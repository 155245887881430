import Component from './component'

export default class Recipe extends Component {
  constructor(params?) {
    super()
    this.params = params
  }
  onAttach() {
    $('.product__tab-info').on('click', (e) => {
      e.preventDefault()
      if (!$(e.target).hasClass('product__tab--active')) {
        $(e.target).toggleClass('product__tab--active')
        $('.product__tab-nutrition').toggleClass('product__tab--active')
        $('.carousel__content').toggleClass('carousel__content--visible')
      }
    })
    $('.product__tab-nutrition').on('click', (e) => {
      e.preventDefault()
      if (!$(e.target).hasClass('product__tab--active')) {
        $(e.target).toggleClass('product__tab--active')
        $('.product__tab-info').toggleClass('product__tab--active')
        $('.carousel__content').toggleClass('carousel__content--visible')
      }
    })
  }
  onDetach() {
    //
  }
}
