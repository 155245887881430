import anime from 'animejs'
import { lockUnlockBody } from '../helpers/body_lock'
import Component from './component'

export default class EasterEgg extends Component {
    windowTop: number
    found: number[]
    pun: string[]
    nextPopup: JQuery<HTMLElement>
        constructor() {
            super()
            this.found = []
            this.pun = [
                'there\'s eggstra hunting to do',
                'keep eggsploring',
                'fair crack!',
                'you\'re a good egg',
                'eggceptional hunting!',
            ]
        }
    onAttach() {
        const previousEggs = JSON.parse(sessionStorage.getItem('foundEggs'))

        if (previousEggs) {
            this.found = previousEggs
            this.checkFound()
        }

        $('.easter-egg__target').on('click', (e) => {
            e.preventDefault()
            this.windowTop = window.pageYOffset || document.documentElement.scrollTop
            lockUnlockBody(true, this.windowTop)
            $('.logo').css('z-index', 61)

            const section = $('#' + $(e.currentTarget).data('section'))
            const popup = $('.easter-egg__popup', section)
            if (this.found.length <= 0) {
                const popupFound = $('.found-eggs .easter-egg__popup')
                history.pushState({}, '', '#found-easter-egg')
                popupFound.addClass('easter-egg__popup--open')
                this.nextPopup = popup
                this.loadPopup(popupFound)
                return
            }
            history.pushState({}, '', '#easter-egg')
            this.openFact(popup)
        })
        $('section .easter-egg__close').not('section.found-eggs .easter-egg__close').on('click', (e) => {
            e.preventDefault()
            lockUnlockBody(false, this.windowTop)
            const popup = $(e.currentTarget).parent().parent().parent()
            history.pushState('', '', window.location.href.replace(/\#(.+)/, '').replace(/http(s?)\:\/\/([^\/]+)/, '') )
            this.closePopup(popup, () => $('.logo').css('z-index', 6))
        })
        $('section.found-eggs .easter-egg__close').on('click', (e) => {
            e.preventDefault()
            lockUnlockBody(false, this.windowTop)
            history.pushState({}, '', '#easter-egg')
            const popup = $(e.currentTarget).parent().parent().parent()
            this.closePopup(popup, this.openFact(this.nextPopup))
            this.nextPopup = null
        })
    }

    onDetach() {
        //
    }
    checkFound() {
        this.found.forEach((index) => {
            $($('.found-eggs-list li')[index - 1]).addClass('found-eggs-list__item--found')
        })
    }
    loadPopup(popup) {
        anime({
            complete: () => {
                anime({
                    elasticity: 0,
                    opacity: 1,
                    targets: $('.easter-egg__content', popup)[0],
                })
            },
            duration: 800,
            easing: [.91, -0.54, .29, 1.56],
            scale: 3,
            targets: $('.easter-egg__goob', popup)[0],
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            translateZ: 0,
        })
    }
    closePopup(popup, callback = null) {
        anime({
            duration: 800,
            elasticity: 0,
            opacity: 0,
            targets: $('.easter-egg__content', popup)[0],
        })
        anime({
            complete: () => {
                popup.removeClass('easter-egg__popup--open')
                if (callback instanceof Function) { callback() }
            },
            delay: 200,
            duration: 800,
            easing: [.91, -0.54, .29, 1.56],
            scale: 0,
            targets: $('.easter-egg__goob', popup)[0],
            translateX: ['-50%', '-50%'],
            translateY: ['-50%', '-50%'],
            translateZ: 0,
        })
    }
    openFact(popup) {
        popup.addClass('easter-egg__popup--open')
        const index = popup.data('index')
        if (this.found.indexOf(index) === -1) {
            $('.found-eggs-pun').text(this.pun[this.found.length])
            this.found.push(index)
            sessionStorage.setItem('foundEggs', JSON.stringify(this.found))
            $('.found-eggs-counter').html(this.found.length.toString())
        }
        this.checkFound()
        this.loadPopup(popup)
    }
}
