export default class ComponentSpec {
    name: string
    class: any
    params: string
    element: JQuery

    constructor(name: string, params: string, element: JQuery) {
        this.name = name
        this.params = params
        this.element = element
    }
}
