import anime from 'animejs'
import Component from '../components/component'

export default class DimReveal extends Component {

  revealEles: JQuery
  revealChildEles: JQuery

  constructor(revealEles: JQuery, revealChildEles: JQuery) {
      super()
      this.revealEles = revealEles
      this.revealChildEles = revealChildEles
  }

  onAttach(): void {
      $(window).on('scroll.reveal', () => {
          this.revealAll()
          this.revealAllChildren()
      })
      $(window).trigger('scroll.reveal')
  }

  onDetach(): void {
      $(window).off('scroll.reveal')
  }

  private checkIfVisible(ele: HTMLElement): boolean {
      const docViewTop = $(window).scrollTop()
      const docViewBottom = docViewTop + ($(window).height() * 0.75)
      const elemTop = $(ele).offset().top
      const elemBottom = elemTop + $(ele).height()

      return (
          (( elemTop >= docViewTop) && (elemTop <= docViewBottom))
          || ((elemBottom >= docViewTop) && (elemBottom <= docViewBottom))
      )
  }

  private doTheReveal(ele: HTMLElement): void {
      $(ele).css('pointer-events', 'none')
      // Config
      const config = {
          complete: (anim) => {
              $(ele).css('will-change', 'auto')
          },
          duration: 450,
          easing: 'easeInOutQuint',
          opacity: [0, 1],
          scaleX: { value: [0.7, 1] },
          scaleY: { value: [0.7, 1] },
          translateZ: 0,
      }
      if (this.checkIfVisible(ele) && !$(ele).hasClass('revealed')) {
          $(ele).css('will-change', 'transform')
          $(ele).addClass('revealed')
          anime({...config, targets: ele})
          $(ele).css('pointer-events', 'auto')
      }
  }

  private revealAll(): void {
      this.revealEles.each((i, ele) => {
          if (!$(ele).hasClass('revealed')) {
              $(ele).css('opacity', 0)
              const delay = parseInt($(ele).data('delay'), 10) || 0
              if (delay) {
                  setTimeout(() => this.doTheReveal(ele), delay)
              } else {
                  this.doTheReveal(ele)
              }
          }
      })
  }

  private revealAllChildren(): void {
      this.revealChildEles.children().each((i, ele) => {
          if (!$(ele).hasClass('revealed')) {
              $(ele).css('opacity', 0)
              const delay = (i + 1) * 100
              setTimeout(() => {
                  this.doTheReveal(ele)
              }, delay)
          }
      })
  }
}
