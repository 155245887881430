import anime from 'animejs'
import Swiper from 'swiper/dist/js/swiper.js'
import { lockUnlockBody } from '../helpers/body_lock'
import { isTablet } from '../helpers/responsive_helpers'
import Component from './component'

export default class Carousel extends Component {
    windowTop: number
    swiper: Swiper
    carousel: JQuery
    logo: JQuery

    constructor(params?) {
        super()
        this.params = params
    }
    onDetach() {
        this.swiper.destroy()
    }
    onAttach() {
        this.carousel = $('.' + this.params)
        this.logo = $('.logo')
        // Open carousel
        $('body').on('click', '.' + this.carousel.prev()[0].className + ' .carousel-tile', (e) => {
            e.preventDefault()
            // lazy click all the info tabs to ensure we show it on open
            $('.product__tab--active').removeClass('product__tab--active')
            $('.product__tab-info').addClass('product__tab--active')
            $('.carousel__content--visible').removeClass('carousel__content--visible')
            $('.product__content--info').addClass('carousel__content--visible')

            this.windowTop = window.pageYOffset || document.documentElement.scrollTop
            lockUnlockBody(true, this.windowTop)
            const slide = $(e.currentTarget)
            const slideNo = slide.data('slideno')
            history.pushState({}, '', '#' + $(this.swiper.slides[slideNo]).attr('id'))
            this.animateTile(slide)
        })

        $(window).on('hashchange', (e) => {
            e.preventDefault()
            const hash = window.location.hash
            if (hash && $(hash).length) {
                if ($(hash, this.carousel).hasClass('carousel-slide')) {
                    const slideNo = parseInt($($(hash)[0]).attr('data-swiper-slide-index'), 10) + 1
                    this.swiper.slideTo(slideNo, 0, false)
                }
            }
            return false
        })
        // Close carousel
        this.carousel.on('click', '.carousel__close, .close-carousel-button', (e) => {
            e.preventDefault()
            $('body').removeClass('body--noscroll')
            history.pushState('', '', window.location.href.replace(/\#(.+)/, '').replace(/http(s?)\:\/\/([^\/]+)/, '') )
            this.logo.fadeIn(200)
            anime({
                complete: () => {
                    this.carousel.removeClass('carousel--open')
                    this.carousel.css('display', 'block')
                },
                opacity: 0,
                targets: this.carousel[0],
            })
            lockUnlockBody(false, this.windowTop)
        })
        this.initSwiper()

        // check if url contains product
        const queryString = window.location.hash
        if (queryString.length) {
            $('.carousel-tile[href="' + queryString + '"]').click()
        }
    }

    private getElementBounds(el) {
        const width = el.width()
        const height = el.height()
        const top = el.offset().top
        const left = el.offset().left
        const transTop = top + (height / 2)
        const transLeft = left + (width / 2)
        return {
            height,
            left,
            top,
            transLeft,
            transTop,
            width,
        }
    }

    private animateTile(tile) {
        // Animate image in
        $('body').addClass('body--noscroll')

        // Disable transform on carousel tile
        $(tile).addClass('carousel-tile--animating')

        const maxWidth: number = $('.product-tile__bg', tile).width()
        const maxHeight: number = $('.product-tile__bg', tile).height()
        const width = $(window).width()
        const height = $(window).height()

        const goobScale = Math.max(width / maxWidth, height / maxHeight) * 2

        $('.product-tile__bg', tile).css({
          transform: 'scale(' + goobScale + ')',
          transition: 'transform 500ms ease-out',
        })
        $('.product-tile__title, .recipe-tile__title, .button', tile).css({
          display: 'none',
        })

        // get thumbnail bounds
        const thumbImage = $('.carousel-tile__image', tile)
        thumbImage.parent().css({transform: 'rotate(0deg) scale(1)', transition: 'none'})
        const thumbBnds = this.getElementBounds(thumbImage)
        thumbImage.parent().attr('style', '')

        // Slide to slide user has clicked on
        this.swiper.slideTo(tile.data('slideno'), 0, false)

        // get image bounds
        const image = $('.swiper-slide-active .carousel__image', this.carousel)
        // add class to reset rotation of image to get accurate bounds
        image.addClass('carousel__image--reset')
        const imageBounds = this.getElementBounds(image)

        // Get scale to animate to
        const scale = imageBounds.width / thumbBnds.width

        // Get difference in position
        const topDiff = imageBounds.transTop - thumbBnds.transTop
        const leftDiff = imageBounds.transLeft - thumbBnds.transLeft

        image.removeClass('carousel__image--reset')

        // Animate thumbnail to position
        const rotate = this.params === 'recipes-carousel' ? 15 : 45

        anime({
            complete: () => {
                setTimeout(() => {
                    thumbImage.attr('style', '')
                    tile.removeClass('carousel-tile--animating')
                    $('.product-tile__bg', tile).attr('style', '')
                    $('.product-tile__title, .recipe-tile__title, .button', tile).attr('style', '')
                }, 300)
            },
            duration: 800,
            elasticity: 200,
            rotate,
            scale,
            targets: $(thumbImage)[0],
            transformOrigin: (leftDiff + (thumbBnds.width / 2)) + 'px ' + (topDiff + (thumbBnds.height / 2)) + 'px 0',
            translateX: leftDiff + 'px',
            translateY: topDiff + 'px',
            translateZ: 0,
        })

        // Open carousel
        this.carousel.addClass('carousel--open')
        this.logo.fadeOut(200)
        anime({
            delay: 500,
            duration: 600,
            easing: 'linear',
            opacity: 1,
            targets: this.carousel[0],
            translateZ: 0,
        })
    }

    // Initialise Slider
    private initSwiper() {
        if ($('.swiper-slide').length > 1 && !this.swiper) {
            this.swiper = new Swiper(this.carousel, {
                allowTouchMove: false,
                effect: 'fade',
                loop: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                on: {
                    slideChangeTransitionStart: () => {
                        const goobyBounce = anime.timeline()
                        if (this.swiper) {
                            history.pushState({}, '', '#' + $(this.swiper.slides[this.swiper.activeIndex]).attr('id'))
                        }
                        if (!isTablet()) {
                            goobyBounce.add({
                                d: 'M100,1000h25V0H100S0,233.22,0,500,100,1000,100,1000Z',
                                targets: '.carousel__svg-path',
                                translateZ: 0,
                            })
                                .add({
                                    d: 'M75,1000h50V0H75S25,233.22,25,500,75,1000,75,1000Z',
                                    offset: '-=800',
                                    targets: '.carousel__svg-path',
                                    translateZ: 0,
                                })
                        }
                    },
                    slideNextTransitionStart: () => {
                        if (!isTablet()) {
                            anime({
                                rotate: ['90deg', '0deg'],
                                targets: '.swiper-slide-active .carousel__image-animate',
                                translateX: ['100vw', '0vw'],
                                translateZ: 0,
                            })
                        }
                    },
                    slidePrevTransitionStart: () => {
                        if (!isTablet()) {
                            anime({
                                rotate: ['-90deg', '0deg'],
                                targets: '.swiper-slide-active .carousel__image-animate',
                                translateX: ['-100vw', '0vw'],
                                translateZ: 0,
                            })
                        }
                    },
                },
            })
        }
    }

}
