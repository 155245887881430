import Carousel from '../components/carousel'
import Component from '../components/component'
import ComponentSpec from '../components/component_spec'
import Contact from '../components/contact'
import EasterEgg from '../components/easter_egg'
import Faq from '../components/faq'
import ImageCarousel from '../components/image_carousel'
import Main from '../components/main'
import Nav from '../components/nav'
import Products from '../components/products'
import Recipe from '../components/recipe'
import Sections from '../components/sections'
import VideoBackground from '../components/video_background'
import VideoOverlay from '../components/video_overlay'
import ModalContent from '../components/modal_content'

export default class Loader {
    static readonly components = {
      Carousel,
      Contact,
      EasterEgg,
      Faq,
      ImageCarousel,
      Main,
      Nav,
      Products,
      Recipe,
      Sections,
      VideoBackground,
      VideoOverlay,
      ModalContent,
    }

    static load(componentItems: ComponentSpec[]): Component[] {
        return componentItems
          .map((spec) => {
            spec.class = Loader.components[spec.name]
            return spec
          })
          .filter((spec) => typeof spec.class !== 'undefined')
          .map((spec) => new spec.class(spec.params, spec.element))
    }
}
