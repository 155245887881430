import anime from 'animejs'
import Swiper from 'swiper/dist/js/swiper.js'
import Component from './component'

export default class Products extends Component {
  swiper: Swiper

  constructor() {
    super()
  }
  onDetach() {
    $('body').off('click', '.product__tab')
    this.swiper.destory()
  }
  onAttach() {

    // Switch tab content for product
    $('body').on('click', '.swiper-slide-active .product__tab', (e) => {
      e.preventDefault()
      $('.swiper-slide-active .product__tab--active').removeClass('product__tab--active')
      $(e.currentTarget).addClass('product__tab--active')
      const ct: HTMLElement = $(e.currentTarget).data('product-content')
      $('.swiper-slide-active .carousel__content--visible').removeClass('carousel__content--visible')
      $('.swiper-slide-active .carousel__content[data-product-content=' + ct + ']').addClass('carousel__content--visible')
    })
  }
}
