import anime from 'animejs'
import Component from './component'

export default class Faq extends Component {

    faqItem: JQuery

    onAttach() {
        this.faqItem = $('.faqs__item')

        this.faqItem.click(function(e) {
            e.preventDefault()
            let height = 0
            $('.faqs__item').not(this).removeClass('faqs__item--open')
            $(this).toggleClass('faqs__item--open')

            if ($(this).hasClass('faqs__item--open')) {
                $('.faqs__answer').css('height', 0)
                $(this).find('.faqs__answer').css('height', 'auto')
            } else {
                $(this).find('.faqs__answer').css('height', 0)
            }
        })
    }

    onDetach() {
        //
    }
}
