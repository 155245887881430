export default abstract class Component {
    attached: boolean = false
    params: any
    element: JQuery

    constructor(params?, element?) {
       this.params = params
       this.element = element
    }

    attach(): void {
        // Only attach if detached
        if (this.attached) { return }
        this.onAttach()
        this.attached = true
    }

    detach(): void {
        // Only detach if attached
        if (!this.attached) { return }
        this.onDetach()
        this.attached = false
    }

    abstract onAttach()

    abstract onDetach()
}
