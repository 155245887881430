/*
 * Get translate values from transform matrix
 * Author: Stack "Dim" Overflow
 * Half modified from: http://radsh.it/PjoBE
 */

const getTranslate = (ele: string): string[] => {
    return $(ele).css('transform').replace(/[^0-9\-.,]/g, '').split(',')
}

export const X = (ele: string): any => {
    const m = getTranslate(ele)
    const eleWidth = $(ele).width()
    const viewportWidth = $(window).width()
    return {
        percent: (parseInt(m[12], 10) / eleWidth) * 100 || (parseInt(m[4], 10) / eleWidth) * 100,
        pixels: m[12] || m[4],
    }
}

export const Y = (ele: string): string => {
    const m = getTranslate(ele)
    return m[13] || m[5]
}
