// support console.log
// import './support/sentry.js'
// import './support/console.js'

import './support/browser'

// Rails unobtrusive scripting adapter
import Rails from 'rails-ujs'
Rails.start()

// Turbolinks speeds up page transitions
// import Turbolinks from 'turbolinks'
// Turbolinks.start()

// import '../helpers/stats'

// Import legacy scripts to global namespace
// import 'script-loader!uglify-loader!jquery'
// import 'script-loader!uglify-loader!jquery-inview'

import Component from './components/component'
import ComponentSpec from './components/component_spec'
import Loader from './support/loader'

class Application {
    components: Component[]

    setup() {
        const componentItems = []

        $('[data-component-name]').each(function() {
          const name = $(this).data('component-name')
          const params = $(this).data('component-params')

          componentItems.push(new ComponentSpec(name, params, $(this)))
        })

        this.components = Loader.load(componentItems)
        this.components.map((v) => v.attach())
    }

    //teardown() {
    //    this.components.map((v) => v.detach())
    //    this.components = []
    //}
}

const application = new Application()

document.addEventListener('DOMContentLoaded', application.setup)
//window.addEventListener('unload', application.teardown)
