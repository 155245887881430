import anime from 'animejs'
import Swiper from 'swiper/dist/js/swiper.js'
import Component from './component'

export default class ImageCarousel extends Component {
    swiper: Swiper
    carousel: JQuery
    transition: string
    freemode: string

    constructor(params?, element?) {
        super()
        this.params = params
        this.element = element
        this.transition = element.data('transition') || 'fade'
        this.freemode = element.data('freemode') || 'false'
    }
    onDetach() {
        this.swiper.destory()
    }
    onAttach() {
        this.initSwiper()
    }

    // Initialise Slider
    private initSwiper() {

        // parallax images exception
        if ($(window).innerWidth() > 1025 && $('.swiper-slide', this.element).parents().hasClass('parallax-area')) {
            $('.our-story__polaroid').removeClass('content-2col-image__img swiper-slide')
            const polaroid = $('.our-story__polaroid')
            $('.parallax-area__polaroid').html(polaroid as any)
        }

        if ($('.swiper-slide', this.element).length > 1 && !this.swiper) {
            this.swiper = new Swiper(this.element, {
                autoplay: {
                  disableOnInteraction: true,
                },
                centeredSlides: true,
                effect: this.transition,
                freeMode: this.freemode,
                loop: true,
                pagination: {
                  clickable: true,
                  el: '.swiper-pagination',
                  type: 'bullets',
                },
                slidesPerView: 'auto',
            })
        }
    }

}
