/*
 * CSS to Hex
 * Author: Stack Dim Overflow
 * Half modified from:
 * https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
 */
const cssToHex = (bg: string): string => {
    const rgb = bg.substring(4, bg.length - 1).split(', ')
    return rgbToHex(rgb[0], rgb[1], rgb[2])
}

const rgbToHex = (r: string, g: string, b: string): string => {
    return `#${intToHex(r)}${intToHex(g)}${intToHex(b)}`
}

const intToHex = (intStr: string): string => {
    const result = parseInt(intStr, 10).toString(16)
    return (result.length === 1) ? '0' + result : result
}

export default cssToHex
