import * as Cookies from 'js-cookie'
import { lockUnlockBody } from '../helpers/body_lock'
import Component from './component'

export default class ModalContent extends Component {
    scrollTop: number
    modalTarget: JQuery

    constructor() {
        super()
        this.modalTarget = $('.modal-content')
    }

    onAttach() {
        if (!Cookies.get('showedDisclaimer')) {
            this.show()
        }
    }

    onDetach() {
        // .
    }

    show() {
        this.scrollTop = $(window).scrollTop()
        lockUnlockBody(true, this.scrollTop)
        this.modalTarget.css('display', 'flex')
        $('.modal-content__close, .modal-content__button').click((e) => {
            e.preventDefault()
            this.hide()
        })
    }

    hide() {
        this.modalTarget.fadeOut(500, () => {
            lockUnlockBody(false, this.scrollTop)
            $('.modal-content__close, .modal-content__button').off()
            Cookies.set('showedDisclaimer', 'true')
        })
    }
}
