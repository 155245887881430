import { lockUnlockBody } from '../helpers/body_lock'
import Component from './component'

export default class VideoOverlay extends Component {
    scrollTop: number
    iframeTarget: JQuery
    overlayTarget: JQuery

    constructor() {
        super()
        this.overlayTarget = $('.video-overlay')
        this.iframeTarget = $('.video-overlay__iframe')
    }

    onAttach() {
        $('.content-2col-image__wrap--video').click((e) => this.show(e))
        $('.video-overlay__hide, .video-overlay__link').click((e) => {
            e.preventDefault()
            this.hide()
        })
        $('.video-overlay').click(() => this.hide())
    }

    onDetach() {
      // .
    }

    show(e) {
        this.scrollTop = $(window).scrollTop()
        const videoId = this.iframeTarget[0].dataset.videoId
        this.iframeTarget.attr('src', `https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0&controls=0&showinfo=0`)
        $(this.overlayTarget).addClass('show')
        $('.logo, .nav-button').css('z-index', '1')
        lockUnlockBody(true, this.scrollTop)
    }

    hide() {
        $('.nav').css('display', 'block')
        $(this.overlayTarget).removeClass('show')
        $('.logo').css('z-index', '6')
        $('.nav-button').css('z-index', '55')
        this.iframeTarget.attr('src', '')
        lockUnlockBody(false, this.scrollTop)
    }
}
