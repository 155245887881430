import anime from 'animejs'

export default class HeaderGoog {
  config: anime.AnimeAnimParams
  animation: anime.AnimeInstance
  svgWrapper: JQuery<HTMLElement>
  path: JQuery<HTMLElement>
  constructor(wrapper) {
    this.svgWrapper = wrapper
    this.path = $('.section-header__path', this.svgWrapper)
    this.config = {
      autoplay: false,
      d: this.path.attr('pathdata:id'),
      direction: 'alternate',
      duration: 10000,
      easing: 'easeInOutQuad',
      loop: true,
      targets: this.path[0],
      translateZ: 0,
    }
    this.animation = anime(this.config)
    this.svgWrapper.on('inview', (event, isInView) => {
      if (isInView) {
        this.animation.play()
      } else {
        this.animation.pause()
      }
    })
  }
}
