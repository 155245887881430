// Import and run old browser warning scripts
import browserUpdate from 'browser-update';

var versionSupport = -5, // 5 latest versions of every browser
    config = {
      notify: {
        i: 10,
        f: versionSupport,
        o: versionSupport,
        s: -2,
        c: versionSupport
      },
    };

document.addEventListener("DOMContentLoaded", function(event) {
  browserUpdate(config);
});
