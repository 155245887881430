import skrollr from 'skrollr'
import WebFont from 'webfontloader'
import DimReveal from '../helpers/dim-reveal'
import * as Helpers from '../helpers/responsive_helpers'
import Component from './component'

export default class Main extends Component {

    instance: any
    dimReveal: DimReveal

    onAttach() {
        if (!Helpers.isMobile() && !Helpers.isTablet()) {
            window.addEventListener('load', () => {
                this.instance = skrollr.init({ forceHeight: false })
            })
        }
        this.setupDimReveal()
        this.loadFonts()
        $('.footer-sole__button').click((e) => {
            e.preventDefault()
            this.scrollToTop()
        })
        $(window).on('hashchange', (e: any) => {
            e.preventDefault()
            const hash = window.location.hash
            let hashReferrerValue = ''
            if (e.originalEvent) {
                hashReferrerValue = e.originalEvent.oldURL.substr(e.originalEvent.oldURL.indexOf('#'))
            }
            if (!hash) {
                $('.carousel__close').click()
                if (hashReferrerValue === '#easter-egg') {
                    $('section .easter-egg__close').not('section.found-eggs .easter-egg__close').click()
                }
                if (hashReferrerValue === '#found-easter-egg') {
                    $('section.found-eggs .easter-egg__close').click()
                }
            }
            return false
        })
        $(window).on('load', () => {
            const hash = window.location.hash
            let location
            let distance
            if (Helpers.isMobile()) {
              distance = 40
            } else {
              distance = 300
            }
            if (hash === '#recipes') {
              location = $(hash).offset().top
              window.scroll({
                behavior: 'smooth',
                left: 0,
                top: location + distance,
              })
            }
            $('.section-header--recipes').addClass('visible')
            $('.recipe-tiles').addClass('visible')
        })
    }

    onDetach() {
        //
    }

    setupDimReveal(): void {
        const reveal = $('[data-fade-in="true"]')
        const revealChildren = $('[data-fade-in-children="true"]')
        this.dimReveal = new DimReveal(reveal, revealChildren)
        this.dimReveal.attach()
    }

    scrollToTop() {
        $(window).scrollTop(0)
    }

    loadFonts() {
      WebFont.load({
        google: {
          families: ['Quicksand:400,500,700'],
        },
      })
    }
}
