import anime from 'animejs'
import { lockUnlockBody } from '../helpers/body_lock'
import cssToHex from '../helpers/css_to_hex'
import * as GetTranslate from '../helpers/get_translate'
import * as Helpers from '../helpers/responsive_helpers'
import Component from './component'

interface Config {
    duration: number
    easing: any
    offset: number
    targets: string
    translateX?: any[]
    translateZ?: string,
}

interface FlipInitial {
    readonly backgroundColor?: string
    readonly fill?: string
    readonly d?: string
    readonly left?: string
    readonly opacity?: string
    readonly zIndex?: string
    readonly scaleX?: any
    readonly scaleY?: any
    readonly transformX?: any
    readonly transformY?: any
}

interface Flip {
    readonly selector: string
    readonly initial: FlipInitial,
    readonly scaleXTo?: number,
    readonly scaleYTo?: number,
}

export default class Nav extends Component {
    windowTop: number
    navOpen: boolean
    navLink: JQuery
    navButton: JQuery
    nav: JQuery

    eggwash: Flip
    eggwashPath: Flip
    navOverlay: Flip
    navBgOuter: Flip
    navBgInner: Flip
    navBgCircleInner: Flip
    navDrawer: Flip

    easingBounce: number[]

    onAttach() {
        this.navOpen = false
        this.nav = $('.nav')
        this.navButton = $('.nav-button')
        this.navLink = $('.nav-drawer-list__link')

        this.navOverlay = {
            initial: {
                opacity: $('.nav-overlay').css('opacity'),
                zIndex: $('.nav-overlay').css('z-index'),
            },
            selector: 'nav-overlay',
        }
        this.navBgOuter = {
            initial: {
                transformX: GetTranslate.X('.nav-bg--outer'),
            },
            selector: 'nav-bg--outer',
        }
        this.navBgInner = {
            initial: {
                transformX: GetTranslate.X('.nav-bg--inner'),
            },
            selector: 'nav-bg--inner',
        }
        this.navBgCircleInner = {
            initial: {
                backgroundColor: $('.nav-bg__circle--inner').css('backgroundColor'),
            },
            selector: 'nav-bg__circle--inner',
        }

        this.eggwash = {
            initial: {
                opacity: $('.eggwash').css('opacity'),
                transformX: GetTranslate.X('.eggwash'),
            },
            selector: 'eggwash',
        }
        if (!Helpers.isMobile() && !Helpers.isTablet()) {
          this.eggwash = {
            ...this.eggwash,
            scaleXTo: 2,
            scaleYTo: 2,
          }
        } else {
          this.eggwash = {
            ...this.eggwash,
            scaleXTo: 2,
            scaleYTo: 4,
          }
        }
        this.eggwashPath = {
            initial: {
                d: $('.eggwash__path').attr('d'),
            },
            selector: 'eggwash__path',
        }
        this.navDrawer = {
            initial: {
                backgroundColor: $('.nav-drawer').css('backgroundColor'),
                transformX: GetTranslate.X('.nav-drawer'),
            },
            selector: 'nav-drawer',
        }
        this.easingBounce = [0.785, -0.550, 0.230, 1.510]

        this.navButton.click((e) => {
            e.preventDefault()
            this.navOpen = !this.navOpen

            if (this.navOpen) {
                this.showNav()
            } else {
                this.resetNav()
            }
        })

        this.navLink.click((e) => this.eggwashTransition(e))

        $('.nav-overlay').click(() => this.resetNav())
    }

    onDetach() {
        //
    }

    private animeConfig(duration: number, easing: any, offset: number, member: Flip, percent?: string) {

        // Get current position and animate to new % position if specified.
        // Else get current position and animate to origin page load position %.

        let config: Config = {
            duration,
            easing,
            offset,
            targets: '.' + member.selector,
            translateZ: '0',
        }

        // Only set translateX if member has transformX defined

        if (member.initial.transformX) {
            const currPos = GetTranslate.X('.' + member.selector).percent
            const originPos = member.initial.transformX.percent
            const translateX = percent ? [currPos, percent] : [currPos, originPos]

            config = { ...config, translateX }
        }

        return config
    }

    private showNav(): void {
        const timeline = anime.timeline()

        this.navButton.addClass('is-active')
        this.nav.addClass('is-active')

        timeline
            .add({
                ...this.animeConfig(1, 'easeOutQuart', 0, this.navOverlay, '0%'),
                zIndex: 49,
            })
            .add({
                ...this.animeConfig(500, 'easeOutQuart', 0, this.navOverlay, '0%'),
                opacity: 0.7,
            })
            .add({
              ...this.animeConfig(500, this.easingBounce, 0, this.navBgOuter, '-70%'),
              opacity: 1,
            })
            .add({
              ...this.animeConfig(650, this.easingBounce, 0, this.navBgInner, '-70%'),
              opacity: 1,
            })
            .add({
              ...this.animeConfig(500, this.easingBounce, 0, this.navBgCircleInner, '0%'),
              opacity: 1,
            })
            .add(this.animeConfig(500, 'easeOutQuart', 300, this.navDrawer, '0%'))
            .add({
                ...this.animeConfig(500, this.easingBounce, 300, this.eggwash, '0%'),
                scaleX: { value: 0.1 },
                scaleY: { value: 0.1 },
            })
        this.windowTop = window.pageYOffset || document.documentElement.scrollTop
        lockUnlockBody(true, this.windowTop)
    }

     private resetBgInner(offset = 0): void {
         anime(this.animeConfig(500, this.easingBounce, offset, this.navBgInner))

         const timeline = anime.timeline()

         timeline
             .add({
                 ...this.animeConfig(500, this.easingBounce, offset, this.navBgCircleInner, '0%'),
             })
             .add({
                 ...this.animeConfig(100, 'easeOutQuart', offset + 500, this.navBgCircleInner),
                 backgroundColor: this.navBgCircleInner.initial.backgroundColor,
             })
     }

     private resetBgOuter(offset = 0): void {
         anime(this.animeConfig(650, this.easingBounce, offset, this.navBgOuter))
     }

    private resetNavDrawer(offset = 0): void {
        anime(this.animeConfig(500, 'easeInQuart', offset, this.navDrawer))
        anime({
            ...this.animeConfig(500, 'easeOutQuart', offset, this.navOverlay),
            opacity: this.navOverlay.initial.opacity,
            zIndex: this.navOverlay.initial.zIndex,
        })
    }

    private resetEggwash(offset = 0): void {
        const timeline = anime.timeline()

        timeline
            .add({
                ...this.animeConfig(500, 'easeOutQuart', offset, this.eggwash),
                opacity: 0,
                scaleX: { value: this.eggwash.scaleXTo },
                scaleY: { value: this.eggwash.scaleYTo },
            })
            .add({
                ...this.animeConfig(750, 'easeOutQuart', offset + 500, this.eggwash),
                opacity: 0,
                scaleX: { value: 0.1 },
                scaleY: { value: 0.1 },
            })
            .add({
                ...this.animeConfig(100, 'easeOutQuart', offset + 500 + 750, this.eggwash),
                opacity: this.eggwash.initial.opacity,
                scaleX: { value: 0.1 },
                scaleY: { value: 0.1 },
            })
    }

    private resetNav(): void {
        this.navOpen = false

        this.nav.removeClass('is-active')
        this.navButton.removeClass('is-active')

        this.resetNavDrawer()
        this.resetEggwash()
        this.resetBgInner(200)
        this.resetBgOuter(350)
        lockUnlockBody(false, this.windowTop)
    }

    private eggwashTransition(e): void {
        e.preventDefault()
        lockUnlockBody(false, this.windowTop)
        const target = $(e.target).attr('href')

        // update url to be shared
        window.history.pushState(target.split('#')[1], target.split('#')[1], '/' + target);

        let targetColor
        if ($(target + ' .section-header__video').length) {
          targetColor = $(target + ' .section-header__video').css('background-color')
        } else {
          targetColor = $(target + ' .section-header__content').css('background-color')
        }
        const fill = cssToHex(targetColor)
        const path = $('.eggwash__path')
        const d = path.attr('pathdata:id')

        const timeline = anime.timeline()

        timeline
            .add({
                ...this.animeConfig(1100, 'easeOutQuad', 0, this.eggwash, '0%'),
                fill,
                scaleX: { value: this.eggwash.scaleXTo },
                scaleY: { value: this.eggwash.scaleYTo },
            })
            .add({
                ...this.animeConfig(1100, 'easeOutQuad', 0, this.eggwashPath, '0%'),
                d,
            })
            .add({
                ...this.animeConfig(1100, 'easeOutQuad', 0, this.navBgCircleInner, '0%'),
                backgroundColor: fill,
                complete: () => this.sectionTransition(target),
            })
        this.resetNavDrawer()
        this.nav.removeClass('is-active')
        this.navButton.removeClass('is-active')
    }

    private sectionTransition(target: string): void {
        const targetOffset = $(target).offset().top
        const targetContentOffset = $(target + ' .section-header').offset().top
        const headerHeight = $(target + ' .section-header').outerHeight()
        const windowHeight = $(window).height()

        $('html, body').scrollTop(targetOffset - windowHeight)
        $(target + ' .section-header__content').css('z-index', 51)
        $('html, body').animate(
            // scroll to middle of header
            { scrollTop: (targetContentOffset + (headerHeight / 2)) - ($(window).height() / 2)},
            {
                complete: () => {
                    this.resetBgInner()
                    this.resetBgOuter()
                    this.resetEggwash(900)
                    setTimeout(() => {
                        $(target + ' .section-header__content').css('z-index', '')
                    }, 1500)
                    this.navOpen = false
                },
                duration: 1000,
            },
        )
    }
}
