import anime from 'animejs'
import * as _ from 'lodash'
import * as Helpers from '../helpers/responsive_helpers'
import Component from './component'
import HeaderGoog from './header_googs'

export default class Sections extends Component {
    googs: object[]
    onAttach() {
        this.googs = []
        $('.section-header__svg-wrap').each((index, el) => {
            this.googs.push(new HeaderGoog($(el)))
        })

        $('.scroll-helper').click((e) => this.scrollToContent(e))

        $(window).on('scroll', _.throttle(this.changeBg, 250).bind(this))
        this.changeBg()

        this.setHeaderHeights()
    }

    onDetach() {
        //
    }

    private scrollToContent(e: any): void {
        e.preventDefault()

        const target = $(e.target).parents('section').find('.section-content').offset().top

        $('html, body').animate(
            { scrollTop: target },
            { duration: 500 },
        )
    }

    private updateCurrLocationUrl(text: string): void {
        // update url with current location
        let urlCurrLocation = text.replace(' ', '-')
        if (urlCurrLocation === 'hello-there!' ) {
            urlCurrLocation = ""
        }
        window.history.pushState(urlCurrLocation, urlCurrLocation, '/#' + urlCurrLocation);
    }

    private updateCurrLocation(text: string): void {
        // update nav text with current location
        $('.nav-button__curr-location').text(text)
        anime({
            marginLeft: ['0px', '20px'],
            opacity: [0, 1],
            targets: '.nav-button__curr-location',
            translateZ: 0,
        })
    }

    private changeBg(): void {
        const titleOffsets = []
        const sectionBgs = ['orange']
        const sectionTitles = ['hello there!']
        const currScroll = $(window).scrollTop() + $(window).height() / 2
        let i = 0
        let currBg = 0

        $('.section-header__title').each(function() {
            titleOffsets.push($(this).offset().top)
            sectionBgs.push($(this).parents('section').data('bg'))
            sectionTitles.push($(this).parents('section').attr('id').replace('-', ' '))
        })

        for (i = 0; i < titleOffsets.length; i++) {
            if (titleOffsets[i] < currScroll) {
                currBg = i + 1
            }
        }

        if (!$('.section-bg').hasClass(`section-bg--${sectionBgs[currBg]}`)) {
            $('.section-bg').removeClass((index, className) => {
                return (className.match(/(^|\s)section-bg--\S+/g) || []).join(' ')
            })

            $('.section-bg').addClass(`section-bg--${sectionBgs[currBg]}`)
            this.updateCurrLocation(sectionTitles[currBg])
            // this.updateCurrLocationUrl(sectionTitles[currBg])
        }
    }

    private setHeaderHeights(): void {
        const winHeight = $(window).innerHeight()
        let headerHeight = null

        if (Helpers.isMobile()) {
            headerHeight = winHeight * 1.1
        } else if (Helpers.isTablet()) {
            headerHeight = winHeight * 1.3
        } else {
            headerHeight = winHeight * 1.6
        }

        $('.section-header').not('.section-header--no-stretch').height(headerHeight)
    }
}
