import Component from './component'

export default class Contact extends Component {

    onAttach() {
        // adding the class here and we remove it in the enquiry create/error.js.erb area
        $('.contact-form__footer button').click((e) => {
            $('.contact-form__footer').addClass('submitting')
        })
    }

    onDetach() {
        //
    }
}
